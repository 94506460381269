.ant-layout .ant-layout-sider .ant-menu {
  background-color: #fff;
}
aside {
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
}
.ant-layout .ant-menu .ant-menu-item {
  color: #182a4b;
  font-weight: 500;
}
.ant-layout .ant-menu .ant-menu-item:hover {
  background-color: #f3f4f6;
  color: #182a4b;
}
.ant-layout .ant-menu .ant-menu-item.active,
.ant-layout .ant-layout-sider .ant-menu .ant-menu-item-selected.active {
  color: #fff;
  background-color: #182a4b;
}

.ant-layout .ant-table th::before {
  display: none;
}
.ant-tabs-nav-wrap {
  border-bottom: 5px solid #2490ff;
}
.ant-tabs-tab {
  padding: 15px 28px 6px;
  margin-left: 0 !important;
  font-weight: bold;
}
.ant-tabs-tab-active {
  border-radius: 30px 30px 0 0;
  background-color: #182a4b;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
label {
  font-weight: bold;
}
.ant-radio-group label,
.ant-checkbox-group label {
  font-weight: normal;
}

.ant-radio-inner {
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.date-picker {
  box-shadow: inset 0 2px 5px 0 rgb(0 0 0 / 20%);
}

/* end of style css */